<template>
  <!-- 承接中 货单跟踪 1qx 货源找车2xw（货主确认） -->
  <div class="carrier-report-wrap">
    <div class="flex mb20">
      <el-col :span="5" class="flex-c">
        <div class="label main-text">总计  </div>
        <div class="value"> <span class="main-c"> {{ confirmInfo.carCount||"-" }} </span>车</div>
      </el-col>
    </div>
    <div class="flex-c mb20">
      <el-col :span="5">
        <el-input v-model="form.truckNo" placeholder="输入车牌号/姓名">
          <i slot="suffix" class="cursor hover-main el-input__icon el-icon-search" @click="search" />
        </el-input>
      </el-col>
    </div>
    <div v-for="(item, index) in tableData" :key="index" class="g-search-form-table">
      <div class="mb20">
        <div class="flex-c">
          <div class="mr20">
            <UserInfoCard :user-info="item" :user-type="2" />
          </div>
          <div class="mr20">
            <div class="value">
              <span class="mr10">上报 {{ item.submitCarCount }} 车</span>
              <span class="mr10">上报价格</span>
              <!-- <span class="mr10"> {{ $CONSTANT.priceTypeMap[+item.submitPriceType]||'-' }}</span> -->
              <span>{{ $parseNumber(item.submitPrice)||'-' }} {{ $CONSTANT.transportPriceUnitMap.O[+confirmInfo.priceUnit] }}</span>
              <span :class="['ml5',$CONSTANT.taxColorMap[+item.needTax]]">{{ $CONSTANT.taxMap[+item.needTax] }}</span>
              <span class="ml10 mr10">信息费</span>
              <span>{{ +item.msgPriceType===2?$parseNumber(item.msgPrice):'-' }}元/车</span>
            </div>
          </div>

          <div class="mr20">报车渠道：平台定向</div>
          <!-- <div>小五姓名：胡晓晓</div> -->
        </div>
      </div>
      <Table
        v-loading="loading"
        :table-cols="tableCols"
        :table-data="item.carOrderListVOList"
        :show-pagination="false"
      >
        <template v-slot="{ col, row }">
          <div v-if="col.prop === 'truckNo'" class="ellipsis">
            <span class="mr5">{{ row.truckNo||'-' }}</span>
            <span :class="$CONSTANT.carTypeColorMap[row.isWx]">{{ $CONSTANT.carTypeMap[row.isWx]||'-' }}</span>
          </div>
          <div v-if="col.prop === 'driverName'" class="ellipsis">
            <span class="mr5">{{ row.driverName||'-' }}</span>
            <span>{{ row.driverPhone||'-' }}</span>
          </div>
          <div v-if="col.prop === 'escortName'" class="ellipsis">
            <span class="mr5">{{ row.escortName||'-' }}</span>
            <span>{{ row.escortPhone||'-' }}</span>
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import wayBill from '@/api/transport/wayBill';
export default {
  props: {
    orderId: {
      type: [Number, String],
      default() {
        return 1;
      },
    },
    gId: {
      type: [Number, String],
      default() {
        return 1;
      },
    },
  },

  data() {
    return {
      form: {},
      loading: false,
      tableCols: [
        { label: '车牌号', prop: 'truckNo', type: 'customize' },
        { label: '挂车号', prop: 'guaNo' },
        { label: '驾驶员', prop: 'driverName', type: 'customize', width: 160 },
        { label: '押运员', prop: 'escortName', type: 'customize', width: 160 },
        { label: '上报时间', prop: 'createTime', type: 'filterTime', width: 165 },
        { label: '小五姓名', type: 'objShow', objShow: { keyPar: 'xwUserVO', keyChild: 'name' }},
      ],
      confirmInfo: {},
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      const params = {
        search: this.form.truckNo,
        gId: this.gId, // 货单id
        tId: this.orderId, // 运单id
        state: '-1,3,5,7,8,9', // state -1,3,5,7,8,9 已确认 4,6取消
        post: this.$store.state.common.AUV_ROLE_CODE,
      };
      wayBill.orderCarTransTransportList(params).then(res => {
        this.confirmInfo = res || {};
        this.tableData = res?.carOrderReportGroupVOList	 || [];
      }).finally(() => {
        this.loading = false;
      });
    },
    search() {
      this.getList();
    },
  },
};

</script>
