import request from '@/plugins/axios';
function pickTrue(obj) {
  const newObj = {
    ...obj,
  };
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item] && newObj[item] !== 0) {
      delete newObj[item];
    }
  });
  return newObj;
}
const prefix = '/auv';
export default {
  // 货单执行统计
  orderGoodsCountDetail: function(params) {
    return request.post(`${prefix}/order/goods/count/detail`, pickTrue(params));
  },
  // 运单执行统计
  orderTransCountDetail: function(params) {
    return request.post(`${prefix}/order/trans/count/detail`, pickTrue(params));
  },
  // 车单执行统计
  orderCarCountDetail: function(params) {
    return request.post(`${prefix}/order/car/count/detail`, pickTrue(params));
  },

};
