import request from '@/plugins/axios';

const prefix = '/auv';
export default {
  goodsProcess: function(params) {
    return request.post(`${prefix}/order/goods/process/detail`, params);
  },
  transProcess: function(params) {
    return request.post(`${prefix}/order/trans/process/detail`, params);
  },
  carOrderProcess: function(params) {
    return request.post(`${prefix}/order/carorder/process/detail`, params);
  },
};

