<template>
  <div class="timeline">
    <div v-for="(n) in processNodesData" :key="n.nodeType" :class="['timeline-item',+timelineDetail[n.prop]>0?'active':'']">
      <div class="timeline-item_icon">
        <!-- v-if="timelineDetail[n.prop]>0" -->
        <i class="el-icon-check" />
        <!-- <i v-else class="el-icon-more" /> -->
      </div>
      <div class="timeline-item_wrap">
        <div class="title">
          {{ n.label }}
          <span v-if="timelineDetail[n.prop]>0&&type!==3" class="num">{{ timelineDetail[n.prop] }}</span>
        </div>
        <div class="time">
          <div v-if="type===3">{{ timelineDetail[n.prop] | parseTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import processNodes from '@/api/processNodes';
import { parseTime } from '@/utils';
export default {
  filters: {
    parseTime,
  },
  props: {
    orderId: {
      type: Number,
      default() {
        return 0;
      },
    },
    // 三种类型 货单（1.1定价 1.2竞价） 运单2 车单3
    type: {
      type: [String, Number],
      default() {
        return 1.1;
      },
    },
    // 是否需要调取接口
    needAxios: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // 不需要调取接口时得外部数据
    externalData: {
      type: Object,
      default() {
        return {
          carCount: 0,
          confirmCount: 0,
          loadSignCount: 0,
          loadCount: 0,
          unloadSignCount: 0,
          unloadCount: 0,
          finish: 0,
          end: 0,
          pushTme: 0,
        };
      },
    },
  },
  data() {
    return {
      processNodesDataMap: {
        1.1: [
          { label: '确认', prop: 'confirmCount' },
          { label: '到装', prop: 'loadSignCount' },
          { label: '装货', prop: 'loadCount' },
          { label: '到卸', prop: 'unloadSignCount' },
          { label: '卸货', prop: 'unloadCount' },
          { label: '磅单核定', prop: 'finish' },
          { label: '已完成', prop: 'end' },
        ],
        1.2: [
          { label: '车数', prop: 'carCount' }, // 竞价多这个节点
          { label: '确认', prop: 'confirmCount' },
          { label: '到装', prop: 'loadSignCount' },
          { label: '装货', prop: 'loadCount' },
          { label: '到卸', prop: 'unloadSignCount' },
          { label: '卸货', prop: 'unloadCount' },
          { label: '磅单核定', prop: 'finish' },
          { label: '已完成', prop: 'end' },
        ],
        2: [
          { label: '到装', prop: 'loadSignCount' },
          { label: '装货', prop: 'loadCount' },
          { label: '到卸', prop: 'unloadSignCount' },
          { label: '卸货', prop: 'unloadCount' },
          { label: '磅单核定', prop: 'finish' },
          { label: '已完成', prop: 'end' },
        ],
        3: [
          { label: '派单', prop: 'pushTme' }, // 派单时间
          { label: '到装', prop: 'loadSignCount' }, // 到装时间
          { label: '装货', prop: 'loadCount' },
          { label: '到卸', prop: 'unloadSignCount' },
          // { label: '卸货', prop: 'unloadCount' },
          { label: '运输完成', prop: 'unloadCount' }, // 运输完成时间
          { label: '磅单核定', prop: 'finish' },
        ],
      },
      timelineDetail: {
        carCount: 0,
        confirmCount: 0,
        loadSignCount: 0,
        loadCount: 0,
        unloadSignCount: 0,
        unloadCount: 0,
        finish: 0,
        end: 0,
        pushTme: 0,
      },
    };
  },
  computed: {
    processNodesData() {
      return this.processNodesDataMap[this.type];
    },
  },
  // watch: {
  //   orderId: {
  //     handler(val) {
  //       this.getData();
  //     },
  //     immediate: true,
  //   },
  // },
  created() {
    if (this.needAxios) {
      this.getData();
    } else {
      this.timelineDetail = this.externalData?.keyMap || this.timelineDetail;
    }
  },
  methods: {
    async getData() {
      try {
        const apiTypeMap = {
          1.1: 'goodsProcess',
          1.2: 'goodsProcess',
          2: 'transProcess',
          3: 'carOrderProcess',
        };
        const timelineDetail = await processNodes[apiTypeMap[this.type]]({ id: this.orderId });
        if (timelineDetail.keyMap) {
          this.timelineDetail = timelineDetail.keyMap;
        }
      } catch (error) {
        console.log(error, 'error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  display: flex;
  padding-top: 18px;
  .timeline-item {
    position: relative;
    border-top: 2px solid #c0c4cc; //#10ce66;
    padding: 20px 14px 0 0;
    flex: 1;
    .timeline-item_icon {
      position: absolute;
      width: 24px;
      height: 24px;
      top: -13px;
      left: 0;
      background: #fff;
      border: 1px solid #c0c4cc;
      color: #c0c4cc;
      border-radius: 50%;
      line-height: 24px;
      text-align: center;
      i {
        font-size: 16px;
      }
    }
    &:last-child {
      border-color: transparent !important;
    }
    .timeline-item_wrap {
      .title {
        font-size: 14px;
        color: #c0c4cc;
        .num {
          font-size: 12px;
          color: #5a6073;
        }
      }
      .time {
        width: 102px;
        padding-top: 8px;
        font-size: 12px;
        color: #5a6073;
      }
    }
    // &.arrive {
    //   .timeline-item_icon {
    //     color: #10ce66;
    //     border-color: #10ce66;
    //   }
    //   .timeline-item_wrap {
    //     .title {
    //       color: #04122b;
    //     }
    //   }
    // }

    &.active {
      border-top-color: #10ce66;
      .timeline-item_icon {
        color: #10ce66;
        border-color: #10ce66;
      }
      .timeline-item_wrap {
        .title {
          color: #04122b;
        }
      }
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
</style>
