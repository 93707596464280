import request from '@/plugins/axios';
function pickTrue(obj) {
  const newObj = {
    ...obj,
  };
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item] && newObj[item] !== 0) {
      delete newObj[item];
    }
  });
  return newObj;
}
// 详情左侧为组件 数据结构不一致 故转义同货单详情结构一致
export const getDetailType = {
  res: (d) => {
    return {
      ...d,
      goodsStop: d.extMap ? d.extMap.goodsStop || '-' : '-',
      tags: d.extMap.tags || '',
    };
  },
};
const prefix = '/auv';
export default {
  // 运单列表
  orderCarTransList: function(params) {
    return request.post(`${prefix}/order/car/trans/list`, pickTrue(params));
  },
  // 运单列表导出
  orderCarTransExport: function(params) {
    return request.post(`${prefix}/order/car/trans/export`, pickTrue(params));
  },
  // 运单详情
  orderTransOrderDetail: function(id) {
    return request.get(`${prefix}/order/transorder/detail`, { id: id }, { showLoading: false }).then((res) => {
      res = getDetailType.res(res);
      return res;
    });
  },
  // 承运运单车单列表
  orderCarTransTransportList: function(params) {
    return request.post(`${prefix}/order/car/trans/transport/list`, pickTrue(params));
  },
};
