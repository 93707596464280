<template>
  <div class="num-total-wrap flex-c">
    <div v-for="(item, index) in transportationData" :key="index" class="flexc">
      <div v-if="item.prop === 'orderTotal'" class="label-text has-msg-price">
        <div class="flex-c">
          <span class="label">{{ item.label }}</span>
          <span v-if="transportationForm.goodsReportVO&&+transportationForm.goodsReportVO.priceType===1" class="fz_12">
            {{ bigNumber(transportationForm.goodsReportVO.price,100,'div') }}
            {{ $CONSTANT.transportPriceUnitMap.O[+transportationForm.priceUnit] }}
          </span>
          <span v-if="transportationForm.goodsReportVO&&+transportationForm.goodsReportVO.priceType===2" class="fz_12">
            {{ bigNumber(transportationForm.goodsReportVO.showPrice,100,'div') }}
            {{ $CONSTANT.transportPriceUnitMap.O[+transportationForm.priceUnit] }}
          </span>
        </div>
        <!-- 竞价报车不显示信息费 -->
        <div v-if="orderPriceType===1" class="info-price">
          <span class="label fz_12">信息费</span>
          <span v-if="transportationForm.goodsReportVO&&+transportationForm.goodsReportVO.priceType===2" class="fz_12">
            {{ bigNumber(transportationForm.goodsReportVO.price,100,'div') }}元/车
          </span>
          <span v-else>-</span>
        </div>
      </div>
      <div v-else class="label-text">{{ item.label }}
      </div>
      <div class="num">
        <div v-if="item.prop === 'orderTotal'">
          <span class="main-text">
            {{ bigNumber(transportationForm.recTotal,1000,'div') }}
          </span>
          <span class="unit">吨</span>
          <span class="division-line" />
          <span class="main-text">
            {{ transportationForm.carNum ||'-' }}
          </span>
          <span class="unit">车</span>
        </div>
        <div v-else-if="item.prop === 'loadTotal'">
          <span class="main-text">
            {{ bigNumber(transportationForm.loadTotal,1000,'div') }}
          </span>
          <span class="unit">吨</span>
          <span class="division-line" />
          <template v-if="!item.hideCar">
            <span class="main-text">
              {{ transportationForm.loadCarNum }}
            </span>
            <span class="unit">车</span>
          </template>
          <!-- <img
            src="@/assets/images/goodsOrder/red-top.png"
          > -->
          <!-- <img
            src="@/assets/images/goodsOrder/red-top.png"
          > -->
        </div>
        <div v-else-if="item.prop === 'dischargeTotal'">
          <span class="main-text">
            {{ bigNumber(transportationForm.unloadTotal,1000,'div') }}
          </span>
          <span class="unit">吨</span>
          <span class="division-line" />
          <template v-if="!item.hideCar">
            <span class="main-text">
              {{ transportationForm.unloadCarNum }}
            </span>
            <span class="unit">车</span>
          </template>
        </div>
        <div v-else-if="item.prop === 'loss'">
          <span class="main-text">
            {{ bigNumber(transportationForm.lossCount,1000,'div') }}
          </span>
          <span class="unit">吨</span>
          <span class="division-line" />
          <span :class="['unit-percent', +transportationForm.lossCountPercent > planPercent ? 'red-c' : 'main-c']">{{ transportationForm.lossCountPercent||'-' }}‰</span>
          <!-- <span :class="['unit-percent', 'main-c']">{{ transportationForm.lossPercent||'-' }}‰</span> -->
        </div>
        <div v-else-if="item.prop === 'unfinish'">
          <!-- <span class="main-text">
            {{ bigNumber(transportationForm.unFinishCount,1000,'div') ||'-' }}
          </span>
          <span class="unit">吨</span>
          <span class="division-line" /> -->
          <span class="main-text">
            {{ transportationForm.unFinishCar ||'-' }}
          </span>
          <span class="unit">车</span>
        </div>
        <div v-else-if="item.prop === 'preLoad'">
          <span class="main-text">
            {{ bigNumber(transportationForm.preLoadNum,1000,'div') ||'-' }}
          </span>
          <span class="unit">吨</span>
          <span class="division-line" />
          <span class="main-text">
            {{ transportationForm.preLoadCarNum ||'-' }}
          </span>
          <span class="unit">车</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import totalNum from '@/api/totalNum';
import Big from 'big.js';
export default {
  props: {
    // 三种左侧类型 货单1 运单2 车单3
    type: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    orderId: {
      type: [String, Number],
      default: '',
    },
    api: {
      type: String,
      default: 'orderGoodsCountDetail',
    },
    totalNumParams: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      transportationDataMap: {
        1: [
          { label: '承接', prop: 'orderTotal' },
          { label: '实际装货', prop: 'loadTotal' },
          { label: '实际卸货', prop: 'dischargeTotal' },
          { label: '损耗', prop: 'loss' },
          { label: '未完成量', prop: 'unfinish' },
        ],
        2: [
          { label: '承接', prop: 'orderTotal' },
          { label: '实际装货', prop: 'loadTotal' },
          { label: '实际卸货', prop: 'dischargeTotal' },
          { label: '损耗', prop: 'loss' },
          { label: '未完成量', prop: 'unfinish' },
        ],
        3: [
          // { label: '承接数量', prop: 'orderTotal' },
          { label: '实际装货', prop: 'loadTotal' }, // hideCar: true
          { label: '实际卸货', prop: 'dischargeTotal' },
          { label: '损耗', prop: 'loss' },
          { label: '预装', prop: 'preLoad' },
        ],
      },
      transportationForm: {
        acceptPrice: '', // 承接价格
        recTotal: '', // 承接吨数
        carNum: '', // 承接数量
        loadTotal: '', // 实际装货吨数
        loadCarNum: '', // 实际装货车数
        unloadTotal: '', // 实际卸货
        unloadCarNum: '', // 实际卸货车数
        lossCount: '', // 损耗
        lossCountPercent: '', // 损耗百分比
        planPercent: '',
        // unFinishCount: '', // 未完成吨数
        unFinishCar: '', // 未完成车数
        preLoadNum: '', // 预装吨数
        preLoadCarNum: '', // 预装车数
      },
    };
  },

  computed: {
    transportationData() {
      return this.transportationDataMap[this.type];
    },
    planPercent() {
      if (this.$parent.orderInformation && this.$parent.orderInformation?.extMap && +this.$parent.orderInformation.extMap.permitLose) {
        return this.bigNumber(+this.$parent.orderInformation?.extMap.permitLose, 100, 'div');
      } else {
        return 0;
      }
    },
    orderPriceType() {
      if (this.$parent.orderInformation) {
        // 1定价 2竞价
        return +this.$parent.orderInformation.type;
      } else {
        return 1;
      }
    },
  },
  created() {
    this.getTotalNum(this.orderId);
  },
  methods: {
    bigNumber(num, multiple, type) {
      if (num === '' || num === 'undefined' || num === '-' || num === undefined || num === null) {
        return '-';
      } else {
        // 乘以
        if (type === 'times') {
          return Math.round(new Big(+num).times(multiple) * 1000) / 1000;
        } else {
          // 除以
          return Math.round(new Big(+num).div(multiple) * 1000) / 1000;
        }
      }
    },
    // 运输数量
    getTotalNum(id) {
      totalNum[this.api]({ id, post: this.$store.state.common.AUV_ROLE_CODE }).then((res) => {
        // 损耗
        // if (res.loadTotal > 0 && res.unloadTotal > 0) {
        //   res.lossCount = new Big(+res.loadTotal).minus(+res.unloadTotal);
        // } else {
        //   res.lossCount = '-';
        // }
        // // 损耗比
        // if (res.lossCount >= 0 && res.loadTotal >= 0) {
        //   res.lossCountPercent = Math.round((res.lossCount / res.loadTotal) * 1000 * 100) / 100;
        // } else {
        //   res.lossCountPercent = '-';
        // }
        // 未完成量 车
        if (res.recCarCount) {
          res.unFinishCar = new Big(+res.recCarCount).minus(+res.realUnloadCarNum || 0);
        }
        // 未完成量 吨
        // if (res.realLoadNum) {
        //   res.unFinishCount = new Big(+res.realLoadNum).minus(+res.realUnloadNum || 0);
        // }
        // if (this.$parent.orderInformation && this.$parent.orderInformation.extMap) {
        //   res.planPercent = +this.$parent.orderInformation?.extMap.permitLose || 0;
        // } else {
        //   res.planPercent = 0;
        // }
        this.transportationForm = {
          goodsReportVO: res.goodsReportVO,
          acceptPrice: res.recPrice, // 承接价格
          priceUnit: res.priceUnit || 1,
          recTotal: res.recTotal, // 承接吨数
          carNum: res.recCarCount, // 承接车数量
          loadTotal: res.realLoadNum, // 实际装货吨数
          loadCarNum: res.realLoadCarNum, // 实际装货车数
          unloadTotal: res.realUnloadNum, // 实际卸货吨数
          unloadCarNum: res.realUnloadCarNum, // 实际卸货车数
          lossCount: res.loss, // 损耗
          lossCountPercent: this.bigNumber(res.lossPer, 1000, 'times'), // 损耗百分比
          // planPercent: this.bigNumber(res.planPercent, 100, 'div'), // 计划得损耗百分比
          // unFinishCount: res.unFinishCount, // 未完成吨数
          unFinishCar: res.unFinishCar, // 未完成车数
          preLoadNum: res.preLoadNum, // 预装吨数
          preLoadCarNum: res.preLoadCarNum, // 预装车数
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.num-total-wrap {
  height: 86px;
  border: 1px solid #dcdfe6;
  > div {
    border-right: 1px solid #dcdfe6;
    width: 25%;
    padding-left: 20px;
    .num {
      margin-top: 17px;
      >div{
        display: flex;
        align-items: center;
      }
      .division-line{
        width: 5px;
        height: 14px;
        margin:0 6px 0 2px;
        border-right: 1px solid #101010;
      }
      .unit,
      .unit-percent {
        font-size: 14px;
        font-weight: normal;
        color: #5a6073;
      }
      .main-c{
        color: $blue;
      }
      .red-c{
        color: $red;
      }
    }
    &:last-child {
      border-right: 0;
    }
  }
  .has-msg-price{
    height: 33px;
    margin-bottom: -15px;
    .label{
      display: inline-block;
      width: 37px;
    }
    .info-price{
      margin-top: -4px;
    }
  }
}
</style>
