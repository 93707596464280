<template>
  <div class="content-detail-wrap flex">
    <Left v-if="!isfoldLeft" id="left-wrap" class="left" :order-information="orderInformation" :title-type="titleType" type="2" />
    <div :class="['middle card-padding', isfoldLeft ? 'middle-all' : 'middle-half']">
      <div id="scroll-wrap" ref="scrollWrap" class="scroll-wrap">
        <div class="card-title flex-c">
          <span>{{ titleType }}详情</span>
          <i class="el-icon-refresh" @click="refreshLineNum" />
          <img class="cursor" src="@/assets/images/common/tansport.svg" @click="openMap">
        </div>
        <div class="time-line-scroll flex">
          <Timeline id="line-date" ref="timeline" :key="topComponentKey" :type="2" class="line-date" :order-id="Number(orderId)" />
        </div>
        <div class="transportation-num">
          <TotalNum :key="topComponentKey" api="orderTransCountDetail" type="2" :order-id="Number(orderId)" />
        </div>
        <div class="table-wrap">
          <div class="arrow" :class="isfoldLeft ? 'arrow-left' : 'arrow1'" @click="foldLeft">
            <i :class="isfoldLeft ? 'el-icon-arrow-right' : 'el-icon-arrow-left'" />
            <span v-if="isfoldLeft">{{ titleType }}信息</span>
          </div>
          <div class="tabs-wrap">
            <Mtabs :tabs="tabs" class="tabs" :init-tab-index="activeTabIndex" @changeTab="changeTab" @refresh="refreshData" />
          </div>
          <div class="tab-down-wrap">
            <HasConfirm v-if="activeTabIndex === 0" :key="componentKey" :order-id="Number(orderId)" :g-id="gId" />
            <HasCancel v-if="activeTabIndex ===1" :key="componentKey" :order-id="Number(orderId)" :g-id="gId" />
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-show="showBackTop" class="back-to-top" @click="backToTop">
          <i class="el-icon-top" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import wayBill from '@/api/transport/wayBill';
import Left from '@/components/OrderBusiness/left';
import Timeline from '@/components/OrderBusiness/timeline';
import TotalNum from '@/components/OrderBusiness/totalNum';
import Mtabs from '@/components/OrderBusiness/Mtabs';
import HasConfirm from './components/hasConfirm';
import HasCancel from './components/hasCancel';
export default {
  // name: 'TransportWaybillDetail',
  components: { Left, Timeline, TotalNum, Mtabs, HasConfirm, HasCancel },
  data() {
    return {
      titleType: '运单',
      topComponentKey: 1,
      componentKey: 1,
      orderId: '', // 运单id
      gId: '', // 货单id
      orderInformation: {}, // 货单信息
      activeTabIndex: 0,
      tabs: [
        { label: '已确认', value: 0 },
        { label: '已取消（货主确认后的取消车辆）', value: 1 },
      ],
      isfoldLeft: false, // 左侧是否展开铺满
      showBackTop: false,
    };
  },
  computed: {
  },
  created() {
    this.orderId = +this.$route.query.id;
    this.gId = +this.$route.query.gId;
    this.activeTabIndex = +this.$route.query.tabIndex || 0;
    this.getDetail();
  },
  mounted() {
    const box = this.$refs.scrollWrap;
    box.addEventListener(
      'scroll',
      () => {
        this.showBackTop = this.$refs.scrollWrap.scrollTop > 215;
      },
      false,
    );
  },
  methods: {
    // 货单信息+货单详情时间轴
    async getDetail() {
      const detail = await wayBill.orderTransOrderDetail(this.orderId).then((res) => {
        return res || {};
      });
      this.orderInformation = detail;
    },
    // 左侧展开
    foldLeft() {
      this.isfoldLeft = !this.isfoldLeft;
    },
    // 切换tab
    changeTab(data) {
      this.activeTabIndex = Number(data.value);
      // 暂无多余tab接入 暂时写法 后期提炼
      this.componentKey = this.componentKey + 1;
    },
    refreshLineNum() {
      this.topComponentKey = this.topComponentKey + 1;
    },
    // tab下重新刷新tab数据
    refreshData() {
      this.componentKey = this.componentKey + 1;
    },
    backToTop() {
      const container = document.querySelector('#scroll-wrap');
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    // 跳转运力监控
    openMap() {
      if (this.orderInformation.id) {
        this.$router.push(`/transport/monitor?id=${this.orderInformation.id}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/goodsOrder.scss";
</style>
